@import "~bootstrap/scss/bootstrap";

.general-block {
    height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
}


.general-logo {
    max-width: 100%;
    padding: 20px;
    text-align: center;
}

.social-logo {
    max-width: 100%;
    padding: 20px;
    text-align: center;
}